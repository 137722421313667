import {React} from 'react';
import {Sheet, Box, Link, Typography} from '@mui/joy';

const AppAnnouncement = ({isMobile, isNative}) => {
  const appStoreMobileUrl = isNative ? `https://app.adjust.com/1hz1319k?fallback=https%3A%2F%2Fapps.apple.com%2Fus%2Fapp%2Fcellartracker-wine-tracking%2Fid6446102275%3Fmt%3D8&redirect_android=https%3A%2F%2Fapps.apple.com%2Fus%2Fapp%2Fcellartracker-wine-tracking%2Fid6446102275%3Fmt%3D8&redirect_ios=https%3A%2F%2Fapps.apple.com%2Fus%2Fapp%2Fcellartracker-wine-tracking%2Fid6446102275%3Fmt%3D8&redirect_macos=https%3A%2F%2Fapps.apple.com%2Fus%2Fapp%2Fcellartracker-wine-tracking%2Fid6446102275%3Fmt%3D8` 
    : `https://app.adjust.com/1hk2221o?fallback=https%3A%2F%2Fapps.apple.com%2Fus%2Fapp%2Fcellartracker-wine-tracking%2Fid6446102275%3Fmt%3D8&redirect_android=https%3A%2F%2Fapps.apple.com%2Fus%2Fapp%2Fcellartracker-wine-tracking%2Fid6446102275%3Fmt%3D8&redirect_ios=https%3A%2F%2Fapps.apple.com%2Fus%2Fapp%2Fcellartracker-wine-tracking%2Fid6446102275%3Fmt%3D8&redirect_macos=https%3A%2F%2Fapps.apple.com%2Fus%2Fapp%2Fcellartracker-wine-tracking%2Fid6446102275%3Fmt%3D8`;
  const playStoreMobileUrl = isNative ? 
    `https://app.adjust.com/1hp5uuaw?fallback=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.cellartracker.appV2&redirect_android=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.cellartracker.appV2&redirect_ios=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.cellartracker.appV2&redirect_macos=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.cellartracker.appV2`
    : `https://app.adjust.com/1hc150ls?fallback=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.cellartracker.appV2&redirect_android=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.cellartracker.appV2&redirect_ios=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.cellartracker.appV2&redirect_macos=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.cellartracker.appV2`;
  return (
    <Sheet
      sx={{
        "& hgroup": {padding: "20px", textAlign: "center"},

        "& .download": {
          margin: "5px 20px",

          "& .store-link": {
            height: "40px", width: "120px"
          },

          "&.desktop": {
            border: "1px solid rgba(0, 0, 0, 0.12)",
            borderRadius: "5px",
            display: "inline-flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "10px",

            "& .qr": {height: "100px", width: "100px", padding: "0 8px"},
          }    
        }
      }}
    >
      <header>
        <Box 
          component="img"
          src={"https://cdn.ct-static.com/img/app-announcement-lightbox-header.png"}
          sx={{
            width: "100%"
          }}
        />
      </header>
      <article>
        <hgroup>
          <Typography
            level="h1"
            sx={{
              fontSize: ['md', 'lg'],
              color: "#000",
              textAlign: "center"
            }}
          >
            New CellarTracker App is Here
          </Typography>
          <Typography
            level="h3"
            sx={{
              fontSize: ['sm', 'md'],
              fontWeight: "bold",
              color: "#5A2230"
            }}
          >
            Access your cellar anywhere, anytime
          </Typography>
        </hgroup>
        <Typography
          sx={{
            fontSize: 'sm',
            padding: ["0 20px", "0 40px"],
            marginBottom: "20px"
          }}
        >
          Track your bottles, record and manage your wine journey. Choose what to drink next and discover new wines, powered by the trusted wine community.
        </Typography>

        {isMobile ?
          <Sheet>
            <Link className="download" href={appStoreMobileUrl}>
              <Box 
                component="img" 
                src="https://cdn.ct-static.com/img/app-store-download.jpg"
                className="store-link"
              />
            </Link>
            <Link className="download" href={playStoreMobileUrl}>
                <Box 
                  component="img"
                  src="https://cdn.ct-static.com/img/play-store-download.jpg"
                  className="store-link"
                />
            </Link>
          </Sheet>
          :
          <Sheet>
            <Box className="download desktop">
              <Box 
                component="img"
                className="qr"
                src={"https://cdn.ct-static.com/img/mobile-store-qr.png"}
                sx={{
                  width: "100%"
                }}
              />
            </Box>
            <Typography
                sx={{
                  fontSize: 'sm',
                }}
            >
              Scan this QR code to download
            </Typography>
          </Sheet>
        }
      </article>
    </Sheet>
  );
};

export default AppAnnouncement;
