import {IconButton} from '@mui/joy';
import {MdKeyboardArrowLeft} from 'react-icons/md';

export default function FormStep({children, currentStep, prevFormStep}) {
  return (
    <>
      {currentStep < 1 && (
        <>
          {currentStep > 0 && (
            <IconButton
              variant="plain"
              color="neutral"
              onClick={prevFormStep}
              type="button"
              sx={{position: 'absolute', top: '-42px', left: '-68px'}}
            >
              <MdKeyboardArrowLeft fontSize="2rem" />
            </IconButton>
          )}
        </>
      )}
      {children}
    </>
  );
}
