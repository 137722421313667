import {React} from 'react';
import {Sheet, Button, Typography} from '@mui/joy';
import { ModalHeader } from '@ct/assemblage';

const SuccessScreen = ({handleClose}) => {
  return (
    <Sheet>
      <ModalHeader>Welcome to CellarTracker</ModalHeader>
      <Typography level="body1" fontSize="sm" lineHeight="md" mb={5}>
        Your account has been created! Please check your email to finish setting up your account.
      </Typography>
      <div className="Modal__footer">
        <Button variant="plain" onClick={handleClose} color="info" level="body3" sx={{fontWeight: 'sm'}}>
          Close and return to view
        </Button>
      </div>
    </Sheet>
  );
};
export default SuccessScreen;
