import React from 'react';
const LightboxContext = React.createContext();

function LightboxProvider({selector = 'lightbox', children}) {
  const lightboxSelector = `#${selector}`;
  const referrer = document?.referrer ?? '';

  const registrationLocalStorageVar = 'registration-last-closed';
  const appAnnouncementLocalStorageVar = 'app-announcement-last-closed';
  
  const now = Date.now();
  const NO_SHOW_PERIOD = 1000 * 60 * 60 * 24 * 14; // 14 days

  const registrationLastClosed = parseInt(
    localStorage.getItem(registrationLocalStorageVar) ?? 0,
    10
  );
  const appAnnouncementLastClosed = parseInt(
    localStorage.getItem(appAnnouncementLocalStorageVar) ?? 0,
    10
  );

  const isGuest = document?.querySelector(lightboxSelector).dataset?.username?.toLowerCase() === 'guest'|| false;
  const noRegister = document?.querySelector(lightboxSelector).dataset?.noregister?.toLowerCase() === 'true' || false;
  const iWine =
    new URLSearchParams(window.location.search).get('iWine') ??
    document?.querySelector(lightboxSelector).dataset?.iwine;
  const isMobile = document?.querySelector(lightboxSelector).dataset?.mobile?.toLowerCase() === 'true' || false;
  const isNative = document?.querySelector(lightboxSelector).dataset?.native?.toLowerCase() === 'true' || false;

  // registration modal has different variations, a user can see one of three variations through random chance
  const registrationVariationArray = ["registration_A", "registration_B", "registration_C"];
  const variationIndex = Math.floor(Math.random() * registrationVariationArray.length);

  // only open lightbox if lightbox was last closed longer than the no show period of both registration and appAnnouncement
  // only guest users can see registration lightbox
  // registration has priority over app announcement
  // noRegister is set on homepage, only app announcement goes there
  const showRegistration = !noRegister && isGuest && (now - registrationLastClosed) > NO_SHOW_PERIOD;
  const showAppAnnouncement = !showRegistration && (now - appAnnouncementLastClosed) > NO_SHOW_PERIOD;

  // one of three possible options for campaign in registration lightbox. only one for app invite
  const campaign = showRegistration ? registrationVariationArray[variationIndex]: `app_invite`;

  const value = {
    iWine,
    isGuest,
    noRegister,
    referrer,
    isMobile,
    isNative,
    registrationLocalStorageVar,
    appAnnouncementLocalStorageVar,
    registrationVariationArray,
    variationIndex,
    showRegistration,
    showAppAnnouncement,
    campaign,
  };
  
  return <LightboxContext.Provider value={value}>{children}</LightboxContext.Provider>;
}

function useLightbox() {
  const context = React.useContext(LightboxContext);
  if (context === undefined) {
    throw new Error(`useLightbox must be used within a LightboxProvider`);
  }
  return context;
}

export {LightboxProvider, useLightbox};
