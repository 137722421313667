import {React, useState} from 'react';
import {Sheet} from '@mui/joy';
import SuccessScreen from './Success';
import FormStep from './FormStep';
import Email from './Email';

const RegistrationForm = ({variationIndex, handleClose, handleRegisterSuccess}) => {
  const [formStep, setFormStep] = useState(0);

  const nextFormStep = () => setFormStep(currentStep => currentStep + 1);
  const prevFormStep = () => setFormStep(currentStep => currentStep - 1);

  return (
    <Sheet>
      <FormStep currentStep={formStep} prevFormStep={prevFormStep}>
        {formStep >= 0 && (
          <Email variationIndex={variationIndex} formStep={formStep} nextFormStep={nextFormStep} onSubmitSuccess={handleRegisterSuccess} />
        )}
        {formStep >= 1 && <SuccessScreen handleClose={handleClose} />}
      </FormStep>
    </Sheet>
  );
};

export default RegistrationForm;
