import {React, useState, useEffect} from 'react';
import {useGA} from '../hooks';
import RegistrationForm from './RegistrationForm';
import AppAnnouncement from './AppAnnouncement';
import {Modal} from '@ct/assemblage';
import {useLightbox} from './LightboxContext';

function LightboxModal() {
  const {
    isMobile,
    isNative, 
    registrationLocalStorageVar, 
    appAnnouncementLocalStorageVar, 
    variationIndex, 
    showRegistration, 
    showAppAnnouncement, 
    campaign
  } = useLightbox();
  const {track} = useGA();
  const [isOpen, setIsOpen] = useState(false);
  const redirect = document?.location?.href;

  const OPEN_DELAY = 5000; // 5 seconds

  useEffect(() => {
    const delayOpen = setTimeout(() => {
      // only open lightbox if at least one of the conditions is met
      if (showRegistration || showAppAnnouncement) {
        openLightbox();
      }

    }, OPEN_DELAY);

    return () => clearTimeout(delayOpen);
  }, []);

  const openLightbox = () => {
    setIsOpen(true);
    track({name: 'lightbox_view', action: 'View', version: campaign});
  };

  const closeLightbox = () => {
    setIsOpen(false);

    if(showRegistration) {
      localStorage.setItem(registrationLocalStorageVar, Date.now().toString());
    } else {
      localStorage.setItem(appAnnouncementLocalStorageVar, Date.now().toString());
    }
  };

  const requestClose = (event, reason) => {
    const action = reason === 'closeClick' ? 'Close' : 'Dismiss';
    const name = `lightbox_${action.toLowerCase()}`;
    track({name, action, version: campaign});
    closeLightbox();
  };

  const onRegisterSuccess = () => {
    track({
      name: 'auth_complete_sign_up',
      action: 'Registration',
      version: campaign,
    });
  };

  // Registration lightbox has precedence over app announcement
  return (
    <Modal 
      open={isOpen} 
      onClose={requestClose}
      layout={isMobile? "fullscreen" : "center"}
    >
      {showRegistration ?
        <RegistrationForm
          variationIndex={variationIndex}
          redirect={redirect}
          handleRegisterSuccess={onRegisterSuccess}
          handleClose={closeLightbox}
        />
      :
        <AppAnnouncement isMobile={isMobile} isNative={isNative}/>}
    </Modal>
  );
}

export default LightboxModal;