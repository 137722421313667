/* eslint-disable no-prototype-builtins */
import {useState, useEffect} from 'react';

const useGA4 = () => {
  const [isInitialized, setIsInitialized] = useState(false);

  const gtag = (...args) => {
    if (!window?.gtag) return new Error('GA4 not initialized');
    return window?.gtag(...args);
  };

  useEffect(() => {
    if (window?.gtag) {
      setIsInitialized(true);
    } else {
      setIsInitialized(false);
    }
  }, []);

  function track({name, action, label, category = 'Lightbox', object, ...additionalProperties}) {
    gtag('event', name, {
      action,
      label: label,
      category: category,
      object: object,
      ...additionalProperties,
    });
  }

  return {
    track,
    isInitialized,
  };
};

export default useGA4;
