import axios from 'axios';

function getApiUrl() {
  if (process.env.CT_API_BASEURL) return process.env.CT_API_BASEURL;
  if (window?.location?.host?.includes('dev.cellartracker.com')) return 'https://api-dev.cellartracker.com';
  return 'https://api.cellartracker.com';
}

let instance = null;

// Create a separate instance of the API for Authentication requests
const getInstance = async () => {
  if (instance !== null) {
    return instance;
  }

  const newInstance = axios.create({
    // base URL is read from the config
    baseURL: getApiUrl(),
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
    timeout: 30000,
  });

  // Set instance variable value to created instance
  instance = newInstance;

  return newInstance;
};

const register = async ({email, acceptTerms = '1', meta}) => {
  const payload = JSON.stringify({
    email,
    acceptTerms,
    metadata: meta,
  });

  const api = await getInstance();
  try {
    const response = await api.post('/users', payload);
    return response.data;
  } catch (err) {
    console.error(err);
    if (err.response) {
      const {
        data: {error, error_data},
      } = err.response;
      // TODO: handling of server error and user exists
      if (error === 'bad_request') return {errors: error_data};
      if (error === 'user_exists') return {errors: {user_exists: {msg: 'An account already exists for this user.'}}};
    }
    return {errors: {server_error: {msg: 'Sorry, your request could not be completed at this time.'}}};
  }
};

const checkEmail = async ({email}) => {
  const payload = JSON.stringify({
    email,
  });

  const api = await getInstance();
  try {
    const response = await api.post('/users/check_email', payload);
    return response.data;
  } catch (err) {
    console.error(err);
    return err?.response?.data;
  }
};

export {register, checkEmail};
